import { JobInfoRecord } from 'src/components/shared/models/job/JobInfoRecord';

function getHasDecisionPointsSelected(jobInfo: JobInfoRecord | undefined): boolean {
  return jobInfo?.status?.has_features_selected ?? false;
}

function getHasNoDocumentsProcessed(jobInfo: JobInfoRecord | undefined): boolean {
  return jobInfo?.status?.processedDocumentsCount === 0;
}

export { getHasDecisionPointsSelected, getHasNoDocumentsProcessed };
