import { JsonObject, JsonProperty } from 'json2typescript';
import RequestCommon from '../utils/RequestCommon';

@JsonObject('UnifiedDecisionPointsJobParams')
export class UnifiedDecisionPointsJobParams {
  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('event_id', String)
  eventId?: string = undefined;
}

@JsonObject('UnifiedDecisionPointsListRequest')
export class UnifiedDecisionPointsListRequest extends RequestCommon {
  @JsonProperty('jobs', [UnifiedDecisionPointsJobParams])
  jobs: UnifiedDecisionPointsJobParams[];
}

type TConstructorParams = {
  jobs: UnifiedDecisionPointsListRequest['jobs'];
};

export function constructUnifiedDecisionPointsListRequest({ jobs }: TConstructorParams): UnifiedDecisionPointsListRequest {
  const instance = new UnifiedDecisionPointsListRequest();
  instance.jobs = jobs;
  return instance;
}
