import { GenericIntegrationRecord } from '@arcanna/models/Flows/GenericIntegrationRecord';
import { JsonObject, JsonProperty } from 'json2typescript';
import { JobInfoProcessorRecord } from 'src/components/shared/models/job/JobInfoProcessorRecord';
import { JobStatusRecord } from './JobStatusRecord';
import { JobCreateInputRecord } from './JobCreateInputRecord';
import { JobInfoDetailRecord } from './JobInfoDetailRecord';
import { AdvancedJobSettings } from './AdvancedJobSettings';
import { JobTimeMetricsRecord } from './JobTimeMetricsRecord';

@JsonObject('JobInfoRecord')
export class JobInfoRecord {
  @JsonProperty('id', Number)
  id: number | undefined;

  @JsonProperty('title', String)
  title: string | undefined;

  @JsonProperty('description', String)
  description: string | undefined;

  @JsonProperty('status', JobStatusRecord)
  status?: JobStatusRecord = undefined;

  @JsonProperty('job_details', JobInfoDetailRecord)
  jobDetails?: JobInfoDetailRecord = undefined;

  @JsonProperty('processor', JobInfoProcessorRecord)
  processor: JobInfoProcessorRecord | undefined;

  @JsonProperty('alerting', JobCreateInputRecord)
  alerting: JobCreateInputRecord | undefined;

  @JsonProperty('advanced_settings', AdvancedJobSettings)
  advancedSettings: AdvancedJobSettings;

  @JsonProperty('metrics', JobTimeMetricsRecord)
  metrics: JobTimeMetricsRecord | undefined;

  @JsonProperty('trained_models_count', Number)
  trained_models_count?: number;

  @JsonProperty('order', [String])
  order: string[];

  @JsonProperty('pipeline_integrations', [GenericIntegrationRecord])
  pipelineIntegrations: GenericIntegrationRecord[];
}
