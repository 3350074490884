import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobInfoProcessorRecord')
export class JobInfoProcessorRecord {
  @JsonProperty('retrain_models', [String])
  retrainModels: string[] | null;

  @JsonProperty('last_model_path', String)
  lastModelPath: string | null;

  @JsonProperty('is_rollback', Boolean)
  isRollback: boolean | null;
}
