export enum EIcon {
  // please use the same string for both value and key in order to auto-generate list in storybook
  ChevronRight = 'ChevronRight',
  CheckCircleOutlined = 'CheckCircleOutlined',
  Close = 'Close',
  ChipClose = 'ChipClose',
  ClipboardPlus = 'ClipboardPlus',
  DotDotDotVertical = 'DotDotDotVertical',
  DotsDrag = 'DotsDrag',
  ExternalLink = 'ExternalLink',
  GoTo = 'GoTo',
  Integration = 'Integration',
  Job = 'Job',
  Lock = 'Lock',
  LockUnlocked = 'LockUnlocked',
  Home = 'Home',
  MessageAlertSquare = 'MessageAlertSquare',
  Settings04 = 'Settings04',
  Edit16 = 'Edit16',
  EyeSlashed = 'EyeSlashed',
  Delete = 'Delete',
  Plus = 'Plus',
  Minimize01 = 'Minimize01',
  ArtificialIntelligenceSpark = 'ArtificialIntelligenceSpark',
  Refresh = 'Refresh',
  Expand01 = 'Expand01',
  Eye = 'Eye',
  Pause = 'Pause',
  Start = 'Start',
  Edit01 = 'Edit01',
  ActionClose = 'ActionClose',
  InfoCircle = 'InfoCircle',
  Save02 = 'Save02',
  ActionSearch = 'ActionSearch',
  ChevronUp = 'ChevronUp',
  ChevronDown = 'ChevronDown',
  FilterLines = 'FilterLines',
  RefreshCCW01 = 'RefreshCCW01',
  Container = 'Container',
  Activity = 'Activity',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success',
  TransitionArrowRight = 'TransitionArrowRight',
  FaceSmile = 'FaceSmile',
  Feedback = 'Feedback',
  AlertTriangle = 'AlertTriangle',
  ApiOutlined = 'ApiOutlined',
  Download01 = 'Download01',
  Calendar = 'Calendar',
  ActivityHeart = 'ActivityHeart',
  Folder = 'Folder',
  FolderPlus = 'FolderPlus',
  Hand = 'Hand',
  Pin = 'Pin',
  PinLeft = 'PinLeft',
  Columns = 'Columns',
  Layout = 'Layout',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  MinusCircle = 'MinusCircle',
  PlusCircle = 'PlusCircle',
  NotFound = 'NotFound',
  InternalError = 'InternalError',
  Copy = 'Copy',
  Code02 = 'Code02',
  Stars01 = 'Stars01',
  File04 = 'File04',
  Variable = 'Variable',
  Terminal = 'Terminal',
  LinkExternal01 = 'LinkExternal01',
  Python = 'Python',
  Shuffle01 = 'Shuffle01',
  DeleteArrow = 'DeleteArrow',
  Play = 'Play',
  CPU = 'CPU',
  Switch = 'Switch',
  CaretUp = 'CaretUp',
  CaretDown = 'CaretDown',
  CodeBlockModal = 'CodeBlockModal',
  ContextEnrichmentModal = 'ContextEnrichmentModal',
  ContextEnrichmentDisabledModal = 'ContextEnrichmentDisabledModal',
  EnrichmentModal = 'EnrichmentModal',
  PostDecisionModal = 'PostDecisionModal',
  TicketingModal = 'TicketingModal',
  ThreeDots = 'ThreeDots',
  SwapRight = 'SwapRight',
  ChevronLeft = 'ChevronLeft',
  ChevronRightDouble = 'ChevronRightDouble',
  ChevronLeftDouble = 'ChevronLeftDouble',
  Send = 'Send',
  ArcannaDarkLogo = 'ArcannaDarkLogo',
  ArrowNarrowRight = 'ArrowNarrowRight'
}
