import { JsonObject, JsonProperty } from 'json2typescript';
import { EnvVariableRecord } from 'src/_srcMUI/models/CodeBlock';
import { CodeBlockSettingsRecord } from 'src/_srcMUI/models/CodeBlock/CodeBlockSettingsRecord';

@JsonObject('CodeBlockIntegrationRecord')
export class CodeBlockIntegrationRecord {
  @JsonProperty('custom_code_id', String)
  codeBlockId: string;

  @JsonProperty('integration_title', String)
  integrationTitle: string;

  @JsonProperty('description', String)
  description: string | null;

  @JsonProperty('input_test', String)
  inputTest: string;

  @JsonProperty('source_code', String)
  sourceCode: string;

  @JsonProperty('env_variables', [EnvVariableRecord])
  envVariables: EnvVariableRecord[];

  @JsonProperty('settings', CodeBlockSettingsRecord)
  settings: CodeBlockSettingsRecord;
}
