import { ConfirmPopover, EIcon, Icon } from '@arcanna/generic';
import { Button, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';

type TDeleteMappingButtonProps = {
  index: number;
  remove: UseFieldArrayRemove;
  disabled: boolean;
};

function DeleteMappingButton({ index, remove, disabled }: TDeleteMappingButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleConfirm = useCallback(() => {
    remove(index);
  }, [index, remove]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Tooltip title="Delete mapping" placement="top" arrow>
        <Button
          color="secondary"
          variant="tertiary"
          size="small"
          className="MuiButton-root--icon"
          disabled={disabled}
          onClick={(event) => handleClick(event)}
        >
          <Icon name={EIcon.Delete} fontSize="small" />
        </Button>
      </Tooltip>
      <ConfirmPopover
        anchor={anchorEl}
        onConfirm={handleConfirm}
        onClose={handleClose}
        text="Are you sure you want to delete this mapping?"
      />
    </>
  );
}

export default DeleteMappingButton;
