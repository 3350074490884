import { Box, Button, ButtonProps, Popover, Stack, useTheme } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Checkbox, EIcon, Icon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

function chunkArray(array: { label: string; value: string }[], chunkSize: number) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

type TPopoverButton = {
  options: { label: string; value: string }[];
  text: string;
  onChange: (selected: string[] | string | undefined) => void;
  defaultValues: string[];
  onSelectAll?: (allSelected: boolean) => void;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
};

export default function PopoverCheckboxGroupButton({
  options,
  text,
  onChange,
  defaultValues,
  onSelectAll,
  buttonProps
}: TPopoverButton) {
  const { t } = useTranslation('common');
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectAll, setSelectAll] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (selected: string[] | string | undefined) => {
    onChange(selected);
  };

  const optionChunks = chunkArray(options, 5);

  return (
    <div>
      <Button
        disableRipple
        variant={'containedHover'}
        color="secondary"
        size="small"
        onClick={handleClick}
        endIcon={open ? <Icon name={EIcon.ChevronUp} /> : <Icon name={EIcon.ChevronDown} />}
        sx={{
          width: '100%',
          height: '40px',
          minWidth: '165px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
        {...buttonProps}
      >
        {text}
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '4px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack flexDirection={'column'} border={`1px solid ${palette.info.main}`} borderRadius={'4px'} padding={'12px'}>
          {onSelectAll && (
            <Box paddingBottom={'8px'}>
              <Checkbox
                state={selectAll ? 'checked' : 'default'}
                value={t('selectDeselectAll')}
                label={`${t('selectDeselectAll')} (${defaultValues.length})`}
                textColor={palette.secondary[300]}
                activeColor={palette.secondary[100]}
                onChange={(_, event) => {
                  setSelectAll(event.target.checked);
                  onSelectAll(event.target.checked);
                }}
              />
            </Box>
          )}
          <Stack flexDirection={'row'}>
            {optionChunks.map((chunk, index) => (
              <Stack key={index} flexDirection="row" paddingLeft={'16px'}>
                <Checkbox.Group
                  key={index}
                  options={chunk}
                  defaultValues={defaultValues}
                  isMultipleChoice={true}
                  onChange={handleChange}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
}
