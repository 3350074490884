import { Button, ButtonProps } from '@mui/material';
import { memo, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../AlertDialog';

type TDeleteButtonProps = {
  handleDelete: () => void;
  modalTitle?: string;
  modalBody?: string;
  buttonText?: string;
  renderCustomButton?: (handleModalOpen: () => void) => ReactNode;
  icon?: ReactNode;
  isIconOnly?: boolean;
} & Omit<ButtonProps, 'onClick'>;

function DeleteButton({
  handleDelete,
  modalTitle,
  modalBody,
  buttonText,
  renderCustomButton,
  icon,
  isIconOnly = false,
  ...otherProps
}: TDeleteButtonProps) {
  const { t } = useTranslation(['common']);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleModalOpen = useCallback(() => setIsDeleteModalOpen(true), [setIsDeleteModalOpen]);

  return (
    <>
      {typeof renderCustomButton === 'function' ? (
        renderCustomButton(handleModalOpen)
      ) : (
        <Button color="secondary" variant="tertiary" onClick={handleModalOpen} {...otherProps}>
          {!isIconOnly && (buttonText ?? t('common:delete'))}
          {icon}
        </Button>
      )}

      <AlertDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        handleSubmit={() => {
          setIsDeleteModalOpen(false);
          handleDelete();
        }}
        title={modalTitle}
        modalBody={modalBody}
      />
    </>
  );
}

export default memo(DeleteButton);
