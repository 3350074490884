import { useMemo } from 'react';

const useThrottleIntervalOptions = (optionsObj: object) => {
  return useMemo(
    () =>
      Object.values(optionsObj).map((intervalTime: string) => ({
        label: intervalTime,
        value: intervalTime
      })),
    [optionsObj]
  );
};

export default useThrottleIntervalOptions;
