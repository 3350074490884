import { EIcon, Icon, Label } from '@arcanna/generic';
import { Button, FormHelperText, Stack, useTheme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeleteMappingButton, MappingSide } from './components';
import { TRHFMappingSideSettings } from './RHFMapping.types';

type TRHFMappingProps = {
  name: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  leftSideSettings: TRHFMappingSideSettings;
  rightSideSettings: TRHFMappingSideSettings;
  addButtonText?: string;
};

function RHFMapping({
  name,
  label,
  tooltipText,
  required,
  leftSideSettings,
  rightSideSettings,
  addButtonText
}: TRHFMappingProps) {
  const { palette } = useTheme();

  const {
    formState: { isSubmitting, errors },
    control,
    trigger
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  const errorOnArray = useMemo(() => errors[name], [errors, name]);

  const errorToShow = useMemo(() => {
    if (typeof errorOnArray?.root?.message === 'string') {
      return errorOnArray.root.message;
    }
    if (typeof errorOnArray?.message === 'string') {
      return errorOnArray.message;
    }
    return '';
  }, [errorOnArray]);

  const handleAddMappingClick = useCallback(async () => {
    append({ from: '', to: '' });
    await trigger(name);
  }, [append, name, trigger]);

  return (
    <Stack>
      <Label text={label ?? ''} required={required} tooltipText={tooltipText} marginBottom="8px" />
      <Stack gap="2px">
        <Stack gap="4px">
          {fields.map((field, idx) => (
            <Stack key={field.id} direction="row" gap={3} alignItems="flex-start">
              <Stack
                direction="row"
                gap={1}
                sx={{
                  flexGrow: 1
                }}
              >
                <MappingSide name={`${name}.${idx}.from`} mappingSideSettings={leftSideSettings} />
                <Icon
                  name={EIcon.ArrowNarrowRight}
                  fontSize="medium"
                  sx={{
                    mt: '8px'
                  }}
                  htmlColor={palette.secondary[300]}
                />
                <MappingSide name={`${name}.${idx}.to`} mappingSideSettings={rightSideSettings} />
              </Stack>
              <DeleteMappingButton index={idx} remove={remove} disabled={isSubmitting} />
            </Stack>
          ))}
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<Icon name={EIcon.Plus} />}
          disabled={isSubmitting}
          onClick={handleAddMappingClick}
          sx={{
            width: 'fit-content'
          }}
          size="small"
        >
          {addButtonText ?? 'Add'}
        </Button>
        {!!errorOnArray && <FormHelperText error>{errorToShow}</FormHelperText>}
      </Stack>
    </Stack>
  );
}

export default RHFMapping;
