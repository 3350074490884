import React from 'react';
import { Box } from '@mui/material';
import StyledDot from './StyledDot.styles';

type TDotProps = {
  delay: string;
};

function Dot({ delay }: TDotProps) {
  return <StyledDot delay={delay} />;
}

function TypingInProgress() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Dot delay="0s" />
      <Dot delay="0.2s" />
      <Dot delay="0.4s" />
    </Box>
  );
}

export default TypingInProgress;
