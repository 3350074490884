/* eslint-disable max-len */
function ArcannaDarkLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
      <rect x="2" y="2" width="48" height="48" rx="7" fill="#0F162B" />
      <path
        d="M36 9.40003C36 10.6151 35.015 11.6 33.8 11.6C32.585 11.6 31.6 10.6151 31.6 9.40003C31.6 8.18501 32.585 7.20003 33.8 7.20003C35.015 7.20003 36 8.18501 36 9.40003Z"
        fill="#F0F6FC"
      />
      <path
        d="M33.8001 13.4C35.0151 13.4 36.0001 14.385 36.0001 15.6V36C36.0001 36.1014 35.9932 36.2011 35.9799 36.2988C36.1084 37.2265 35.6304 38.1692 34.7386 38.5898C33.6397 39.1081 32.3287 38.6375 31.8103 37.5386L23.8202 20.5996L15.997 37.5232C15.4872 38.6261 14.1798 39.1068 13.0769 38.597C11.9741 38.0872 11.4933 36.7798 12.0031 35.6769L21.5875 14.9434C22.4566 13.0632 25.1254 13.0529 26.0091 14.9263L31.6001 26.7792V15.6C31.6001 14.385 32.585 13.4 33.8001 13.4Z"
        fill="#F0F6FC"
      />
      <path
        d="M23.8 35.2C25.015 35.2 26 34.215 26 33C26 31.785 25.015 30.8 23.8 30.8C22.585 30.8 21.6 31.785 21.6 33C21.6 34.215 22.585 35.2 23.8 35.2Z"
        fill="#F0F6FC"
      />
      <path
        d="M13.52 1C6.6054 1 1 6.6054 1 13.52V38.48C1 45.3946 6.6054 51 13.52 51H38.48C45.3946 51 51 45.3946 51 38.48V13.52C51 6.6054 45.3946 1 38.48 1H13.52Z"
        stroke="#FF255A"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ArcannaDarkLogo;
