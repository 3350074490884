import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { config } from 'src/config';
import {
  constructUnifiedDecisionPointsListRequest,
  TUnifiedDecisionPointsResponse,
  TUnifiedDecisionPointsResponseUnparsed
} from '@arcanna/models/Jobs';
import _ from 'lodash';
import { TJobWithEventId } from '@arcanna/pages/DecisionPointsUnified/DPUnifiedContext/DPUnifiedContextProvider';
import { useMemo } from 'react';
import { Serializer } from '@arcanna/utils';

const DEFAULT_STALE_TIME = 300000; // 5 minutes

const URL = config.api.jobFeatureSelectionUnifiedSummary;

const fetchUnifiedDecisionPointsList = (jobs: object) =>
  axios
    .post<{
      resource: { features: TUnifiedDecisionPointsResponseUnparsed[] };
    }>(URL, jobs)
    .then((responseData: AxiosResponse<{ resource: { features: TUnifiedDecisionPointsResponseUnparsed[] } }>) => {
      return {
        features: responseData.data.resource.features.map((feature) => ({
          fieldName: feature.field_name,
          ..._.mapValues(_.omit(feature, 'field_name'), (record) =>
            _.isEmpty(record)
              ? record
              : {
                  isDecisionPoint: record.is_decision_point,
                  isCustomField: record.is_custom_field,
                  sampleValue: record.sample_value,
                  fieldType: record.field_type
                }
          )
        }))
      };
    });

const useUnifiedDecisionPointsList = ({ jobs, enabled }: { jobs: TJobWithEventId[]; enabled?: boolean }) => {
  // PAYLOAD
  const payload = useMemo(() => constructUnifiedDecisionPointsListRequest({ jobs: jobs }), [jobs]);
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery<{ features: TUnifiedDecisionPointsResponse[] }, Error>(
    [URL],
    () => fetchUnifiedDecisionPointsList(payloadSerialized),
    {
      enabled,
      staleTime: DEFAULT_STALE_TIME
    }
  );
};

export default useUnifiedDecisionPointsList;
