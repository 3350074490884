import { Box, Button, ButtonProps, Popover, Stack, useTheme } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Checkbox, EIcon, Icon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

type TPopoverButton = {
  options: { label: string; value: string }[];
  text: string;
  onChange: (selected: string[] | string | undefined) => void;
  defaultValues: string[] | string;
  onSelectAll?: (allSelected: boolean) => void;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
};

export default function PopoverCheckboxButton({
  options,
  text,
  onChange,
  defaultValues,
  onSelectAll,
  buttonProps
}: TPopoverButton) {
  const { t } = useTranslation('common');
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectAll, setSelectAll] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (selected: string[] | string | undefined) => {
    onChange(selected);
  };

  return (
    <div>
      <Button
        disableRipple
        variant={open ? 'containedHover' : 'contained'}
        color="secondary"
        size="small"
        onClick={handleClick}
        endIcon={open ? <Icon name={EIcon.ChevronUp} /> : <Icon name={EIcon.ChevronDown} />}
        sx={{ width: '100%' }}
        {...buttonProps}
      >
        {text}
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '4px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack
          gap={'16px'}
          flexDirection={'column'}
          border={`1px solid ${palette.info.main}`}
          borderRadius={'4px'}
          padding={'12px'}
        >
          <Checkbox.Group onChange={handleChange} isMultipleChoice={true} options={options} defaultValues={defaultValues} />
          {onSelectAll && (
            <Box sx={{ borderTop: '1px solid ' + palette.secondary[700], paddingTop: '16px' }}>
              <Checkbox
                state={selectAll ? 'checked' : 'default'}
                value={t('selectDeselectAll')}
                label={t('selectDeselectAll')}
                textColor={palette.secondary[300]}
                activeColor={palette.secondary[100]}
                onChange={(_, event) => {
                  setSelectAll(event.target.checked);
                  onSelectAll(event.target.checked);
                }}
              />
            </Box>
          )}
        </Stack>
      </Popover>
    </div>
  );
}
