import React from 'react';
import RHFSelect from 'src/_srcMUI/shared/forms/RHFSelect';
import RHFTextField from 'src/_srcMUI/shared/forms/RHFTextField';
import { TRHFMappingSideSettings } from '../../RHFMapping.types';

type TMappingSideProps = {
  name: string;
  mappingSideSettings: TRHFMappingSideSettings;
};

function MappingSide({ name, mappingSideSettings }: TMappingSideProps) {
  if (mappingSideSettings.selection) {
    return (
      <RHFSelect
        name={name}
        options={mappingSideSettings.options}
        placeholder={mappingSideSettings.placeholder}
        triggerValidationOnChange
      />
    );
  }
  return (
    <RHFTextField
      variant="outlined"
      name={name}
      placeholder={mappingSideSettings.placeholder}
      triggerValidationOnChange
      InputProps={{
        sx: { height: '36px' }
      }}
    />
  );
}

export default MappingSide;
