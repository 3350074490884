import { RHFMapping } from '@arcanna/forms';
import { useMemo } from 'react';
import { getFormParameterName } from 'src/_srcMUI/shared/forms/RHFSmartGeneratedField/RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';

function MappingParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  const mappedLeftOptions = useMemo(
    () =>
      parameter.from_values?.selection && parameter.from_values?.options
        ? parameter.from_values?.options.map((option) => ({
            value: option.key,
            label: option.value
          }))
        : [],
    [parameter.from_values?.options, parameter.from_values?.selection]
  );

  const mappedRightOptions = useMemo(
    () =>
      parameter.to_values?.selection && parameter.to_values?.options
        ? parameter.to_values?.options.map((option) => ({
            value: option.key,
            label: option.value
          }))
        : [],
    [parameter.to_values?.options, parameter.to_values?.selection]
  );

  return (
    <RHFMapping
      name={getFormParameterName(parameter, formSectionName)}
      label={parameter.caption}
      tooltipText={parameter.description}
      required={parameter.required}
      // @ts-ignore
      leftSideSettings={{ ...parameter.from_values, options: mappedLeftOptions }}
      // @ts-ignore
      rightSideSettings={{ ...parameter.to_values, options: mappedRightOptions }}
      addButtonText={parameter.add_caption}
    />
  );
}

export default MappingParameter;
