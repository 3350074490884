import { Box, BoxProps } from '@mui/material';
import { keyframes, styled } from '@mui/system';

const typingAnimation = keyframes`
  0%, 60%, 100% {
    transform: translateY(0);
    opacity: 0.4;
  }
  30% {
    transform: translateY(-6px);
    opacity: 1;
  }
`;

const StyledDot = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'delay';
  }
})<BoxProps & { delay: string }>(({ theme, delay }) => ({
  width: 6,
  height: 6,
  margin: '0 2px',
  backgroundColor: theme.palette.secondary[400],
  borderRadius: '50%',
  display: 'inline-block',
  animation: `${typingAnimation} 1.2s infinite`,
  animationDelay: delay
}));

export default StyledDot;
